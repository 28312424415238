<template>
  <div>
    <branch-aside
      v-if="aside === 'branch'"
      :active="true"
      :merchant="merchant"
      :resource="branchData"
      @changed="refresh"
      @aside="bol => { closeAside(bol); !bol && refresh() } "
    />

    <v-card class="mb-2">
      <v-card-title>
        <span>{{ merchant.name.en }}'s Branches</span>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          class="mb-4 me-3"
          @click="toggleAside(null, 'branch')"
        >
          <v-icon>{{ icons.mdiPlus }}</v-icon>
          <span>Add New Branch</span>
        </v-btn>
      </v-card-title>

      <v-data-table
        :headers="tableColumnHeaders"
        :items="branches"
        :sort-by="['id']"
        :sort-desc="[true]"
      >
        <!-- id -->
        <template #[`item.id`]="{item, index}">
          <div class="d-flex align-center">
            {{ ++index }}
          </div>
        </template>

        <!-- name -->
        <template #[`item.name`]="{item}">
          <div class="d-flex align-center">
            {{ t(item.name) }}
          </div>
        </template>

        <!-- address -->
        <template #[`item.address`]="{item}">
          <div class="d-flex align-center">
            {{ t(item.address) }}
          </div>
        </template>

        <!-- active -->
        <template #[`item.active`]="{item}">
          <v-chip :color="item.active ? 'success' : ''">
            {{ item.active ? 'active' : 'inactive' }}
          </v-chip>
        </template>

        <!-- Action -->
        <template #[`item.action`]="{item}">
          <v-menu
            bottom
            left
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item
                link
                @click="toggleAside(item, 'branch')"
              >
                <v-list-item-title>
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiPencil }}
                  </v-icon>
                  <span>Edit</span>
                </v-list-item-title>
              </v-list-item>

              <v-list-item
                link
                @click="deleteBranch(item)"
              >
                <v-list-item-title>
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiDeleteOutline }}
                  </v-icon>
                  <span>Delete</span>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import {
mdiDeleteOutline, mdiDotsVertical, mdiPencil, mdiPencilOutline, mdiPlus,
} from '@mdi/js'
import { computed, ref, watch } from '@vue/composition-api'

import { destroyBranch } from '@/api/merchant/branch'
import { useNotifyErrors } from '@/composables'
import { t } from '@/plugins/i18n'
import BranchAside from '../../branch-resource/BranchAside.vue'

export default {
  components: { BranchAside },

  props: {
    merchant: { type: Object, required: true },
    branches: { type: Array, required: true },
  },

  setup(props, { emit }) {
    const tableColumnHeaders = computed(() => [
      { text: '#', value: 'id', sortable: true },
      { text: 'NAME', value: 'name', sortable: false },
      { text: 'ADDRESS', value: 'address', sortable: false },
      { text: 'ACTIVE', value: 'active', sortable: false },
      { text: 'ACTION', value: 'action', sortable: false },
    ])

    const branchData = ref(null)
    const aside = ref('') // rule, slot, generator

    const closeAside = bol => {
      if (!bol) {
        branchData.value = null
        aside.value = null
      }
    }

    const toggleAside = slot => {
      branchData.value = slot
      aside.value = 'branch'
    }

    const ready = ref(false)

    const refresh = async () => {
      console.log('refresh!')
      ready.value = false
      emit('updated')
      closeAside()
      ready.value = true
    }

    watch(props.merchant, (newMerchant, oldMerchant) => {
      if (newMerchant.id !== oldMerchant.id) {
        console.log('merchant changed', newMerchant)
        refresh()
      }
    })

    const deleteBranch = branch => destroyBranch(branch.id).then(refresh).catch(useNotifyErrors)

    return {
      tableColumnHeaders,
      branchData,
      aside,
      ready,
      refresh,
      closeAside,
      toggleAside,
      t,
      deleteBranch,
      icons: {
        mdiPencil,
        mdiPlus,
        mdiDotsVertical,
        mdiDeleteOutline,
        mdiPencilOutline,
      },
    }
  },
}
</script>

<style scoped>
.cross-out {
  text-decoration: line-through;
  opacity: 0.5;
}
</style>
