import { useMerchantId } from '@/composables'
import axios from '@axios'

const fetchBranches = params => axios.get(`/admin/merchants/${useMerchantId()}/branches`, { params })

const storeBranch = data => axios.post(`/admin/merchants/${useMerchantId()}/branches`, data)

const bulkStoreBranches = (merchantId, branches) => axios.post(`/admin/merchants/${merchantId}/branches/bulk`, { branches })

const updateBranch = (id, data) => axios.put(`/admin/merchants/${useMerchantId()}/branches/${id}`, data)

const destroyBranch = id => axios.delete(`/admin/merchants/${useMerchantId()}/branches/${id}`)

export { bulkStoreBranches, destroyBranch, fetchBranches, storeBranch, updateBranch }
